<template>
    <div class="grid crud-demo">
        <Loader v-model="loading" />
        <Dialog v-model:visible="prospectDialog" :closable="true" :style="{ width: '75vw' }" :modal="true">
            <ProspectUI @update="updateProspect" @save="saveProspect" :modal="true" />
        </Dialog>
        <Dialog v-model:visible="productDialog" :closable="true" :style="{ width: '75vw' }" :modal="true">
            <ConceptUI @save="saveConcept" :isModal="true" />
        </Dialog>
        
        <HelperDialog v-model="prospectHelper" header="Buscar Prospecto" :headers="prospectHeaders" :rows="prospects" @selected="selectProspect" />
        <HelperDialog v-model="productHelper" header="Buscar Producto" :headers="productHeaders" :rows="products" @selected="selectProduct" />
        <HelperDialog v-model="quotationHelper" header="Buscar Cotizacion" :headers="quotationHeaders" :rows="quotations" @selected="selectQuotation" />
        <DeleteDialog v-model:visible="deleteDialog" @closed="deleteDialog = false" @deleted="confirmDelete" />
        <div class="p-col-12">
            <div class="card">
                <Toast />
                <Panel header="Cotizacion">
                    <BasicFormToolbar v-if="!view" @new="openNew" @save="save" @refresh="refresh" @search="(quotationHelper.visible = true)"
                        :actions="modal ? ['new', 'save', 'refresh'] : ['new', 'save', 'refresh', 'search'] " />
                    <div class="grid formgrid p-fluid">
                        <div class="col-12">
                            <Fieldset :toggleable="true" legend="Informacion General">
                                <div class="p-fluid formgrid grid">
                                    <FormInputText :valid="validate.validations.id_crm_prospect" :add="true" @add="(prospectDialog = true)" @search="(prospectHelper.visible = true)" :readonly="true" :search="true" wrapperClass="field col-4" label="Prospecto" ref="nombre" v-model="entity.prospect" />
                                    <FormCalendar :valid="validate.validations.date" wrapperClass="field col-4" label="Fecha" ref="nombre" v-model="entity.date" />
                                    <FormCalendar :valid="validate.validations.expiration_date" wrapperClass="field col-4" label="Fecha Expiracion" ref="nombre" v-model="entity.expiration_date" />
                                    <FormInputText :valid="validate.validations.key_name" :textArea="true" wrapperClass="field col-12" label="Descripcion" v-model="entity.key_name" ></FormInputText>
                                </div>
                            </Fieldset>
                        </div>
                        <div class="col-12"> 
                            <br>
                            <Fieldset :toggleable="true" legend="Productos / Servicios">
                                <BasicFormToolbar @new="openNew" @save="saveDetail" 
                                :actions="['new', 'save']" />
                                <div class="p-fluid formgrid grid">
                                    <FormInputNumber ref="quantity" :valid="validateItem.validations.quantity" wrapperClass="field col-2" label="Cantidad" :min="1" v-model="item.quantity"/>
                                    <FormInputText @enter="(productHelper.visible = true)" @search="(productHelper.visible = true)" @add="(productDialog = true)" :add="true" :valid="validateItem.validations.description" wrapperClass="field col-6" label="Descripcion" v-model="item.description" :search="true" />
                                    <FormInputNumber :valid="validateItem.validations.price" :mode="'currency'" :min="0" :minFractionDigits="2" :maxFractionDigits="2" wrapperClass="field col-2" label="Precio" v-model="item.price" />
                                    <FormInputNumber @enter="saveDetail" @tab="saveDetail" :mode="'currency'" :min="0" :minFractionDigits="2" :maxFractionDigits="2" wrapperClass="field col-2" label="Total" v-model="item.total" :disabled="true" />
                                    <div class="col-12">
                                        <BasicDatatable 
                                        @selected="selectItem"
                                        @deleted="deleteItem"
                                        :dataKey="'id_number'"
                                        :selectionMode="'single'"
                                        :rowaction="true"
                                        :rowdelete="true"
                                        :headers="itemHeaders" 
                                        :headerVisible="false"
                                        :rows="items" /> 
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                    </div>
                </Panel>
            </div>
        </div>
    </div>
</template>
  
<script>

import FormInputText from "../../../components/general/FormInputText.vue";
import BasicFormToolbar from "../../../components/general/BasicFormToolbar.vue";
import DeleteDialog from "../../../components/general/DeleteDialog.vue";
import FormInputNumber from "../../../components/general/FormInputNumber.vue";

import { Concept } from "../../../models/cfdi40/Concept";
import { Prospect } from "../../../models/crm/Prospect";
import { Quotation } from "../../../models/crm/Quotation";
import { QuotationItem } from "../../../models/crm/QuotationItem";
import ProspectUI from '../Catalogos/Prospecto';
import ConceptUI from '../../CFDI40/Catalogos/Concept';

import {
    HeaderGrid,
    Rule,
    validate,
    fillObject,
    Toast,
    ErrorToast,
} from "../../../utilities/General";

import Loader from "../../../components/general/Loader.vue";
import Session from "../../../mixins/sessionMixin";
import FormCalendar from "../../../components/general/FormCalendar.vue";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import HelperDialog from '../../../components/general/HelperDialog.vue';

export default {
    mixins: [Session],
    props: {
        modal: null,
        id: null,
        status: null
    },
    data() {
        return {
            entity: new Quotation(),
            prospectDialog: false,
            productDialog: false,
            quotations: [],
            prospects: [],
            products: [],
            deleteDialog: false,
            item: new QuotationItem(),
            rules: [
                new Rule({ name: "id_crm_prospect" }),
                new Rule({ name: "date" }),
                new Rule({ name: "expiration_date" }),
                new Rule({ name: "key_name" })
            ],
            validate: {
                valid: false,
                validations: {
                    id_crm_prospect: null,
                    date: null,
                    expiration_date: null,
                    key_name: null
                },
            },
            rulesItem: [
                new Rule({ name: "description" }),
                new Rule({ name: "quantity" }),
                new Rule({ name: "price" })
            ],
            validateItem: {
                valid: false,
                validations: {
                    description: null,
                    quantity: null,
                    price: null
                },
            },
            productHeaders: [
                new HeaderGrid("SKU", "key_name"),
                new HeaderGrid("Description", "description"),
                new HeaderGrid("Precio", "unit_value", { type: 'currency' })
            ],
            itemHeaders: [
                // new HeaderGrid("#", "id_number"),
                new HeaderGrid("SKU", "key_name"),
                new HeaderGrid("Cantidad", "quantity"),
                new HeaderGrid("Description", "description"),
                new HeaderGrid("Precio", "price", { type: 'currency' }),
                new HeaderGrid("Total", "total", { type: 'currency', function: 'SUM' }),
            ],
            prospectHeaders: [
                new HeaderGrid("Nombre", "name"),
                new HeaderGrid("Direccion", "address"),
                new HeaderGrid("Email", "email"),
                new HeaderGrid("Telefono", "phone_number"),
            ],
            quotationHeaders: [
                new HeaderGrid("Prospecto", "prospect"),
                new HeaderGrid("Descripcion", "key_name")
            ],
            quotationHelper: {
                visible: false
            },
            prospectHelper: {
                visible: false,
            },
            productHelper: {
                visible: false,
            },
            loading: false,
        };
    },
    components: { ConceptUI, ProspectUI, HelperDialog, BasicDatatable, FormCalendar,FormInputNumber, DeleteDialog, Loader, FormInputText, BasicFormToolbar },
    created() {
        this.entity = new Quotation(this.session);
    },
    async mounted() {
        await this.refresh();
    },
    computed: {
        items() {
            return this.entity.items.filter(x => (x.is_deleted ?? false) == false);
        }
    },
    watch: {
        ["item.price"](newValue) {
            this.item.total = newValue * this.item.quantity;
        },
        ["item.quantity"](newValue) {
            this.item.total = newValue * this.item.price;
        }
    },
    methods: {
        saveConcept(payload) {
            this.products.push(payload);
            this.selectProduct(payload);
            this.productDialog = false;
        },
        updateProspect(payload) { 
            let index = this.prospects.findIndex(x => x.id == payload.id);
            this.prospects[index] = payload;
            this.selectProspect(payload);
            this.prospectDialog = false;        
        },
        saveProspect(payload) {
            this.prospects.push(payload);
            this.selectProspect(payload);
            this.prospectDialog = false;
        },
        selectQuotation(payload) {
            console.log('payload', payload),
            this.entity = fillObject(this.entity, payload);
            this.entity.prospect = payload.prospect;
            this.entity.date = new Date(this.entity.date);
            this.entity.expiration_date = new Date(this.entity.expiration_date);
        },
        deleteItem(payload) {
            payload.is_deleted = true;
        },
        selectItem(payload) {
            this.item = fillObject(this.item, { ...payload });
        },
        selectProspect(payload) {
            this.entity.prospect = payload.name;
            this.entity.id_crm_prospect = payload.id;
        },
        selectProduct(payload) {
            this.item.key_name = payload.key_name;
            this.item.description = payload.description;
            this.item.id_concept = payload.id;
            this.item.price = payload.unit_value;
        },
        saveDetail() {
            try {
                this.validateItem = validate(this.item, this.rulesItem);
                if (!this.validateItem.valid) {
                    throw "Favor de validar los campos";
                }
                this.item.fillSession(this.session);
                let item = { ...this.item};
                if (item.id_number) {
                    let index = this.entity.items.findIndex(x => x.id_number == this.item.id_number);
                    this.entity.items[index] = item;
                }else {
                    item.id_number = item.id_number ?? this.entity.items.length + 1;
                    this.entity.items.push(item);
                }
                this.item = new QuotationItem(this.session);
                this.$refs.quantity.focus();
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
        },
        openNew() {
            this.entity = new Quotation(this.session);
        },
        async save() {
            try {
                //* Validacion de form
                this.loading = true;
                this.validate = validate(this.entity, this.rules);
                if (!this.validate.valid) {
                    throw "Favor de validar los campos";
                }
                //* Si el id es != 0 entonces actualizamos, si no, guardamos
                if (this.entity.id && this.entity.id > 0) {
                    //* Actualizamos
                    let entity = await this.entity.update();
                    this.$toast.add({
                        severity: "success",
                        summary: "Actualizar",
                        detail: "Informacion actualizada con exito",
                        life: 3000,
                    });
                    this.$emit('update', entity);
                    //* Modificamos el listado pah
                    let index = this.quotations.findIndex((x) => x.id == this.entity.id);
                    this.quotations[index] = entity;
                } else {
                    this.entity.status = this.status;
                    let entity = await this.entity.save();
                    //* Agregamos un dato extra
                    this.$emit('save', entity);
                    this.quotations.push(entity);
                    this.$toast.add(
                        new Toast({
                            summary: "Creacion",
                            detail: "Informacion guardada con exito",
                        })
                    );
                }
                this.entity = new Quotation(this.session);
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        edit(entity) {
            this.entity = fillObject(this.entity, entity);
            this.$refs.nombre.focus();
        },
        confirmDelete(entity) {
            this.entity = fillObject(this.entity, entity);
            this.deleteDialog = true;
        },
        async deleteEntity() {
            try {
                this.loading = true;
                //* Eliminamos de la base
                await this.entity.delete();
                //* Eliminamos de la vista
                this.quotations = this.quotations.filter((val) => val.id !== this.entity.id);
                this.deleteDialog = false;
                //* Limpiamos la entidad
                this.entity = new Quotation(this.session);
                this.$toast.add({
                    severity: "success",
                    summary: "Eliminacion",
                    detail: "Registro eliminado con exito",
                    life: 3000,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async refresh() {
            this.loading = true;
            try {
                this.prospects = await new Prospect(this.session).all();
                this.products = await new Concept(this.session).all();
                if (!this.modal)
                    this.quotations = await this.entity.all();
                if (this.id) {
                    this.entity.id = this.id;
                    let entity = await this.entity.retrieve();
                    this.entity = fillObject(this.entity, entity);
                }
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
    }
};
</script>
  
<style scoped lang="scss">
.table-header {
    display: flex;
    justify-content: space-between;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;

        .p-button {
            margin-bottom: 0.25rem;
        }
    }
}
</style>
  