<template>
    <div class="grid p-fluid">
        <Dialog v-model:visible="cotizacionDialog" :closable="true" :style="{ width: '75vw' }" :modal="true">
            <CotizacionUI :status="cotizacionStatus" @save="saveCotizacion" :modal="true" />
        </Dialog>
        <div class="col-3">
            <h2>Registrado<Badge v-if="(cotizaciones.length > 0)" :value="cotizaciones.length" severity="danger" class="mr-2"></Badge></h2>
            <draggable 
                :style="{'overflow-y': 'scroll',
                        'max-height': '35rem'}"
                v-model="cotizaciones" 
                group="people"  
                item-key="id"
                >
                <template #item="{element}">
                    <div :style="{
                        'pointer-events': element.loading ? 'none' : null
                    }" class="card p-card">
                        <div class="p-card-body">
                            <div class="p-card-title">
                                {{element.key_name}}
                            </div>
                            {{element.prospect}}
                        </div>
                    </div>
                </template>
            </draggable>
            <Button @click="newCotizacion('REGISTRADO')" icon="pi pi-plus-circle" label="Agregar" />
        </div>
        <div class="col-3">
            <draggable 
                v-model="contactos" 
                group="people" 
                item-key="id">
                <template #header>
                    <h2>Contacto<Badge v-if="(contactos.length > 0)" :value="contactos.length" severity="warn" class="mr-2"></Badge></h2>
                </template>
                <template #footer>
                    <Button @click="newCotizacion('CONTACTO')" icon="pi pi-plus-circle" label="Agregar" />
                </template>
                <template #item="{element}">
                    <div :style="{
                        'pointer-events': element.loading ? 'none' : null
                    }" :class="{ 
                            card: true,
                            'p-card': true,
                            'loading': element.loading 
                        }">                        
                    <div class="p-card-body">
                            <div class="p-card-title">
                                {{element.key_name}}
                            </div>
                            {{element.prospect}}
                        </div>
                    </div>
                </template>
            </draggable>
        </div>
        <div class="col-3">
            <draggable 
                v-model="propuestas" 
                group="people" 
                item-key="id">
                <template #header>
                    <h2>Propuesta<Badge v-if="(propuestas.length > 0)" :value="propuestas.length" severity="info" class="mr-2"></Badge></h2>
                </template>
                <template #footer>
                    <Button @click="newCotizacion('PROPUESTA')" icon="pi pi-plus-circle" label="Agregar" />
                </template>
                <template #item="{element}">
                    <div class="card p-card">
                        <div class="p-card-body">
                            <div class="p-card-title">
                                {{element.key_name}}
                            </div>
                            {{element.prospect}}
                        </div>
                    </div>
                </template>
            </draggable>
        </div>
        <div class="col-3">
            <draggable 
                v-model="negociaciones" 
                group="people" 
                item-key="id">
                <template #header>
                    <h2>Negociaciones<Badge v-if="(negociaciones.length > 0)" :value="negociaciones.length" severity="success" class="mr-2"></Badge></h2>
                </template>
                <template #footer>
                    <Button @click="newCotizacion('NEGOCIACIONES')" icon="pi pi-plus-circle" label="Agregar" />
                </template>
                <template #item="{element}">
                    <div class="card p-card">
                        <div class="p-card-body">
                            <div class="p-card-title">
                                {{element.key_name}}
                            </div>
                            {{element.prospect}}
                        </div>
                    </div>
                </template>
            </draggable>
        </div>
    </div>
</template>

<script>

import draggable from 'vuedraggable'
import Session from "../../../mixins/sessionMixin";
import { Quotation } from '../../../models/crm/Quotation';
import CotizacionUI from '../Aplicaciones/Cotizacion.vue'

export default {
    mixins: [Session],
    components: {
        draggable, CotizacionUI
    },
    data() {
        return {
            loading: false,
            drag: true,
            cotizaciones: [],
            contactos: [],
            propuestas: [],
            negociaciones: [],
            cotizacionDialog: false,
            cotizacionStatus: null
        }
    },
    watch: {
        async contactos(newValue, oldValue) {
            //Se agrega solamente cuando es nuevo
            if (newValue.length > oldValue.length) {
                for await (const quota of newValue.filter(x => !oldValue.includes(x))) 
                {
                    quota.loading = true;
                    try {
                        await new Quotation(this.session).setStatus(quota.id, 'CONTACTO');
                    } catch (error) {
                        console.log(error);
                    } finally {
                        quota.loading = false;
                    }
                }
            }
        },
        async propuestas(newValue, oldValue) {
            //Se agrega solamente cuando es nuevo
            if (newValue.length > oldValue.length) {
                for await (const quota of newValue.filter(x => !oldValue.includes(x))) 
                {
                    quota.loading = true;
                    try {
                        await new Quotation(this.session).setStatus(quota.id, 'PROPUESTA');
                    } catch (error) {
                        console.log(error);
                    } finally {
                        quota.loading = false;
                    }
                }
            }
        },
        async negociaciones(newValue, oldValue) {
            //Se agrega solamente cuando es nuevo
            if (newValue.length > oldValue.length) {
                for await (const quota of newValue.filter(x => !oldValue.includes(x))) 
                {
                    quota.loading = true;
                    try {
                        await new Quotation(this.session).setStatus(quota.id, 'NEGOCIACIONES');
                    } catch (error) {
                        console.log(error);
                    } finally {
                        quota.loading = false;
                    }
                }
            }
        },
        async cotizaciones(newValue, oldValue) {
            //Se agrega solamente cuando es nuevo
            if (newValue.length > oldValue.length) {
                for await (const quota of newValue.filter(x => !oldValue.includes(x))) 
                {
                    quota.loading = true;
                    try {
                        await new Quotation(this.session).setStatus(quota.id, 'REGISTRADO');
                    } catch (error) {
                        console.log(error);
                    } finally {
                        quota.loading = false;
                    }
                }
            }
        }
    },
    methods: {
        saveCotizacion(cotizacion) {
            this.cotizacionDialog = false;
            if (cotizacion.status == "REGISTRADO") {
                this.cotizaciones.push(cotizacion);
            }else if (cotizacion.status == "CONTACTO") {
                this.contactos.push(cotizacion);
            }else if (cotizacion.status == "PROPUESTA") {
                this.propuestas.push(cotizacion);
            }else if (cotizacion.status == "NEGOCIACIONES") {
                this.negociaciones.push(cotizacion);
            }
        },
        newCotizacion(status) {
            this.cotizacionStatus = status;
            this.cotizacionDialog = true;
        } 
    },
    async mounted() {
        this.loading = true;
        try {
            let cotizaciones = await new Quotation(this.sesison).all();
            this.cotizaciones = cotizaciones.filter(x => x.status == "REGISTRADO");
            this.contactos = cotizaciones.filter(x => x.status == "CONTACTO");
            this.propuestas = cotizaciones.filter(x => x.status == "PROPUESTA");
            this.negociaciones = cotizaciones.filter(x => x.status == "NEGOCIACIONES");
        } catch (error) {
            alert(error);
        } finally {
            this.loading = false
        }
    }
}
</script>

<style>
    .loading {
        background-color: #919191;
        opacity: 0.5;
    }

    *::-webkit-scrollbar {
	width: 5px;
	}

	/* Track */
*::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 50px;
	}

	/* Handle */
*::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 50px;
	}

	/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
	background: #555;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2)
	}
</style>